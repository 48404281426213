/*
 * TA PHISH REPORTER ADD-IN
 * 
*/

// Change Key of Item
var changeKey;
// Receiving Email
var receivingEmail = 'phishing@threatadvice.com';

// On Office Ready, Prepare Document
Office.onReady(function (info) {
    $(document).ready(function () {

        if (typeof Office.context.mailbox === 'undefined') {
            Honeybadger.notify(asyncResult.error, "MailboxUndefinedError");
        } else {
            // Get email items
            var item = Office.context.mailbox.item;

            // Check If Inside Outlook
            if (info.host === Office.HostType.Outlook) {
                document.getElementById("run").onclick = run;

                // Check If Simulation
                checkForSimulationWithAPI(item);

            } else {
                host = info.host;
                document.getElementById("error-1").innerHTML = "Error Code - 4002<br />Context - " + host;
                Honeybadger.notify(createHBError("onReady", "s2"));
            }


            // Close Function
            document.getElementById("closeButton").onclick = function () {
                Office.context.ui.closeContainer();
            };

            document.getElementById("reportButton").onclick = function () {
                // Show Working Message
                showWorkingMessage();

                // Check If Simulation
                buildEmail(Office.context.mailbox.item);
            };
        }

    });
});



// SIMULATION CHECK -------------------------------------------------------------------------------------

function checkForSimulationWithAPI(item) {
    Office.context.mailbox.makeEwsRequestAsync(
        getHeadersForCheck(item.itemId), getHeadersForCheckCallbackMethod);
}

function getHeadersForCheck(itemId) {
    var request =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope' +
        '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
        '  xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
        '  xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
        '  xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '  <soap:Header>' +
        '    <t:RequestServerVersion Version="Exchange2007_SP1" />' +
        '  </soap:Header>' +
        '  <soap:Body>' +
        '    <GetItem' +
        '      xmlns="http://schemas.microsoft.com/exchange/services/2006/messages"' +
        '      xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '      <ItemShape>' +
        '        <t:BaseShape>IdOnly</t:BaseShape>' +
        '        <t:IncludeMimeContent>false</t:IncludeMimeContent>' +
        '        <t:AdditionalProperties>' +
        '           <t:ExtendedFieldURI PropertyTag="0x007D" PropertyType="String" />' +
        '        </t:AdditionalProperties>' +
        '      </ItemShape>' +
        '      <ItemIds>' +
        '        <t:ItemId Id="' + itemId + '" />' +
        '      </ItemIds>' +
        '    </GetItem>' +
        '  </soap:Body>' +
        '</soap:Envelope>';
    return request;
}

function getHeadersForCheckCallbackMethod(asyncResult) {
    document.getElementById("loading-msg").style.display = "none";
    document.getElementById("app-body").style.display = "flex";

    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {

        // parse response
        xmlDoc = $.parseXML(asyncResult.value);
        $xml = $(xmlDoc);
        headers = $xml.find("Value,t\\:Value").text();
        
        // Call API
        apiCall(headers);

    } else {
        Honeybadger.notify(asyncResult.error, "HeadersForCheckError");
    }
}

function showSuccessMessage(simulation) {
    if (simulation) {
        // Show Simulation Success message
        document.getElementById("closeButton").innerHTML = "Close";
        setDisplay("simulation-detected-msg", "block");    
      } else {
        // Show Thank you message
        setDisplay("report-email-msg", "block");
      }
}

function showWorkingMessage() {
  setDisplay("report-email-msg", "none");
  setDisplay("sending-report-msg", "block");
}



// REPORT PROCESS -------------------------------------------------------------------------------------
//
// STEP 1: BUILD NEW EMAIL
function buildEmail(item) {

    try {
        // Get Information
        var body_of_email = "<strong>To: </strong>";

        // Build New Email
        body_of_email += JSON.stringify(item.to);
        body_of_email += "<br><br><strong>From: </strong>";
        body_of_email += JSON.stringify(item.from);
        body_of_email += "<br><br><strong>Subject: </strong>";
        body_of_email += item.subject;
        body_of_email += "<br><br>-----BEGIN EMAIL HEADERS-----<br><br>";

        // STEP 1 - GET HEADERS
        getHeaders(item, body_of_email);
    } catch (error) {
        Honeybadger.notify(error, "BuildEmailError");
    }
}

// STEP 2: GET EMAIL HEADERS
function getHeaders(item, emailToSend) {
    Office.context.mailbox.makeEwsRequestAsync(
        getOldHeaders(item.itemId), oldHeadersCallbackMethod, [item, emailToSend]);
}

// STEP 2A: GET OLD HEADERS
function getOldHeaders(itemId) {
    var request =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope' +
        '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
        '  xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
        '  xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
        '  xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '  <soap:Header>' +
        '    <t:RequestServerVersion Version="Exchange2007_SP1" />' +
        '  </soap:Header>' +
        '  <soap:Body>' +
        '    <GetItem' +
        '      xmlns="http://schemas.microsoft.com/exchange/services/2006/messages"' +
        '      xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '      <ItemShape>' +
        '        <t:BaseShape>IdOnly</t:BaseShape>' +
        '        <t:IncludeMimeContent>false</t:IncludeMimeContent>' +
        '        <t:AdditionalProperties>' +
        '           <t:ExtendedFieldURI PropertyTag="0x007D" PropertyType="String" />' +
        '        </t:AdditionalProperties>' +
        '      </ItemShape>' +
        '      <ItemIds>' +
        '        <t:ItemId Id="' + itemId + '" />' +
        '      </ItemIds>' +
        '    </GetItem>' +
        '  </soap:Body>' +
        '</soap:Envelope>';
    return request;
}

// STEP 2B: OLD HEADERS CALLBACK
function oldHeadersCallbackMethod(asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {

        // parse response and add headers
        xmlDoc = $.parseXML(asyncResult.value);
        $xml = $(xmlDoc);
        headers = $xml.find("Value,t\\:Value").text();

        emailToSend = asyncResult.asyncContext[1];
        emailToSend += headers;
        emailToSend += "<br><br>-----END EMAIL HEADERS-----<br><br>";

        // STEP 3 - GET BODY
        getBody(Office.context.mailbox.item.itemId, emailToSend);

    } else {
        Honeybadger.notify(asyncResult.error, "OldHeadersError");
    }
}

// STEP 3: GET BODY
function getBody(itemId, emailToSend) {
    Office.context.mailbox.makeEwsRequestAsync(getBodyRequest(itemId), getBodyRequestCallback, { itemId: itemId, emailToSend: emailToSend });
}

// STEP 4: GET BODY REQUEST
function getBodyRequest(itemId) {
    var request =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
        '               xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages"' +
        '               xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
        '               xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
        '               xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '  <soap:Header>' +
        '    <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />' +
        '  </soap:Header>' +
        '  <soap:Body>' +
        '    <GetItem' +
        '                xmlns="http://schemas.microsoft.com/exchange/services/2006/messages"' +
        '                xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '      <ItemShape>' +
        '        <t:BaseShape>IdOnly</t:BaseShape>' +
        '           <t:AdditionalProperties>' +
        '               <t:FieldURI FieldURI="item:Body" />' +
        '               <t:FieldURI FieldURI="item:Subject" />' +
        '               <t:FieldURI FieldURI="item:MimeContent" />' +
        '           </t:AdditionalProperties>' +
        '      </ItemShape>' +
        '      <ItemIds>' +
        '        <t:ItemId Id="' + itemId + '"/>' +
        '      </ItemIds>' +
        '    </GetItem>' +
        '  </soap:Body>' +
        '</soap:Envelope>';

    return request;
}

// STEP 4A: GET BODY REQUEST CALLBACK
function getBodyRequestCallback(asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {

        var response = $.parseXML(asyncResult.value);
        var responseObj = $(response);

        // get change key
        var responseDetails = responseObj.xmlNodeSearch('t:ItemId')[0];
        changeKey = responseDetails.getAttribute('ChangeKey');

        // get mime content
        var responseMime = responseObj.xmlNodeSearch('t:MimeContent')[0];
        var mimeContent = responseMime.textContent;



        // Build Final Email to Send
        var item = Office.cast.item.toItemRead(Office.context.mailbox.item);
        var body = responseObj.xmlNodeSearch('t:Body')[0];
        var bodyType = body.getAttribute('BodyType');
        var newEmailBody;

        // Check if attachments are too large to use API
        if (mimeContent === '') {
            newEmailBody = 'ATTACHMENTS TOO LARGE<br><br>';
            if (bodyType == 'HTML') { //escape
                newEmailBody += asyncResult.asyncContext.emailToSend.replace(/(?:\r\n|\r|\n)/g, '<br>')
            } else {
                newEmailBody += asyncResult.asyncContext.emailToSend;
            }
        } else {
            newEmailBody = '';
            if (bodyType == 'HTML') { //escape
                newEmailBody += asyncResult.asyncContext.emailToSend.replace(/(?:\r\n|\r|\n)/g, '<br>')
            } else {
                newEmailBody += asyncResult.asyncContext.emailToSend;
            }
        }


        // Get Links
        if (bodyType == 'HTML') {
            newEmailBody += getLinksFromBody(body.textContent);
        }
        

        // Add body content
        newEmailBody += body.textContent;

        // create send xml envelope
        var sendingXML = createSendingEmailRequest('[POTENTIAL PHISH] ' + Office.context.mailbox.item.subject, mimeContent, newEmailBody, bodyType);

        // send
        Office.context.mailbox.makeEwsRequestAsync(sendingXML, sendCallbackMethod, { headers: { 'Content-Type': 'text/xml; charset=utf-8' } });

    } else {
        Honeybadger.notify(asyncResult.error, "OldHeadersError");
    }

}

// STEP 5: CREATE SENDING EMAIL REQUEST
function createSendingEmailRequest(newSubject, mimeContent, newEmailBody, bodyType) {
    // Prepare all variables
    newSubjectToUse = prepareHtml(newSubject);
    newEmailBodyToUse = prepareHtml(newEmailBody);
    bodySection = buildBodySectionOfRequest(newEmailBodyToUse, bodyType);
    xmlForAttachments = buildAttachmentSectionOfRequest(mimeContent);


    var request =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
        '               xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages"' +
        '               xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
        '               xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
        '               xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
        '  <soap:Header>' +
        '    <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />' +
        '  </soap:Header>' +
        '  <soap:Body>' +
        '    <m:CreateItem MessageDisposition="SendAndSaveCopy">' +
        '	      <m:Items>	' +
        '	        <t:Message>	' +
        '	          <t:Subject>' + newSubjectToUse + '</t:Subject>	' +
        bodySection +
        xmlForAttachments +
        '           <t:ToRecipients>' +
        '               <t:Mailbox>' +
        '                   <t:EmailAddress>' + receivingEmail + '</t:EmailAddress>' +
        '               </t:Mailbox>' +
        '           </t:ToRecipients>' +
        '	        </t:Message>	' +
        '	      </m:Items>	' +
        '	    </m:CreateItem>	' +
        '  </soap:Body>' +
        '</soap:Envelope>';

    return request;
}

// STEP 5A: BUILD BODY SECTION OF REQUEST
function buildBodySectionOfRequest(body, bodyType) {
    if (bodyType == 'HTML') {
        return '<t:Body BodyType="HTML">' + body + '</t:Body>';
    } else {
        return '<t:Body BodyType="Text">' + body + '</t:Body>';
    }
}

// STEP 5B: BUILD ATTACHMENT SECTION OF REQUEST
function buildAttachmentSectionOfRequest(mimeContent) {
    if (mimeContent === '') {
        return '';
    } else {
        var xmlToReturn =
            '	          <t:Attachments>	' +
            '	            <t:ItemAttachment>	' +
            '	              <t:Name>SourceMessage</t:Name>	' +
            '	              <t:IsInline>false</t:IsInline>	' +
            '	              <t:Message>	' +
            '                   <t:MimeContent CharacterSet="UTF-8">' + mimeContent + '</t:MimeContent>' +
            '                   <t:ExtendedProperty>' +
            '                       <t:ExtendedFieldURI PropertyTag="0x0E07" PropertyType="Integer"/>' +
            '                       <t:Value>0</t:Value>' +
            '                   </t:ExtendedProperty>' +
            '	              </t:Message>	' +
            '	            </t:ItemAttachment>	' +
            '	          </t:Attachments>	';

        return xmlToReturn;
    }
}

// STEP 5C: SEND CALLBACK
function sendCallbackMethod(asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {

        // Delete Email
        Office.context.mailbox.makeEwsRequestAsync(
            deleteEmail(Office.context.mailbox.item.itemId), deleteCallbackMethod);

    } else {
        Honeybadger.notify(asyncResult.error, "SendEmailError");
    }
}

// STEP 6: DELETE EMAIL
function deleteEmail(itemId) {
    var request =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
        '             xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages"' +
        '             xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types"' +
        '             xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">' +
        '<soap:Header>' +
        '  <t:RequestServerVersion Version="Exchange2013" />' +
        '</soap:Header>' +
        '<soap:Body>' +
        '  <m:MoveItem>' +
        '    <m:ToFolderId>' +
        '      <t:DistinguishedFolderId Id="deleteditems" />' +
        '    </m:ToFolderId>' +
        '    <m:ItemIds>' +
        '      <t:ItemId Id="' + itemId + '"' +
        '                ChangeKey="' + changeKey + '" />' +
        '    </m:ItemIds>' +
        '  </m:MoveItem>' +
        '</soap:Body>' +
        '</soap:Envelope>';

    return request;
}

// STEP 6A: DELETE CALLBACK
function deleteCallbackMethod(asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        if (Office.context.requirements.isSetSupported('Mailbox', '1.4')) {
            Office.context.ui.closeContainer();
        } else {
            document.getElementById("thanks").innerHTML = "Your message has been sent. You may now close the add-in.";
        }
    } else {
        Honeybadger.notify(asyncResult.error, "DeleteEmailError");
        if (Office.context.requirements.isSetSupported('Mailbox', '1.4')) {
            Office.context.ui.closeContainer();
        } else {
            document.getElementById("thanks").innerHTML = "Your message has been sent. You may now close the add-in.";
        }
    }
}



// HELPER METHODS -------------------------------------------------------------------------------------
//
// Get Links Helper
function getLinksFromBody(body) {

    // Get Links
    var linksString = "";
    var finalLinksStrings = "";
    $(body).find("a").each(function () {
        var href = $(this).attr('href');
        if (href) {
            linksString += href + "<br>";
        }
    });
    if (linksString.length > 0) {
        finalLinksStrings += "<br>-----BEGIN LINKS-----<br><br>";
        finalLinksStrings += linksString;
        finalLinksStrings += "<br>-----END LINKS-----<br><br>";
    }


    return finalLinksStrings;
}

// XML Node Search
(function ($) {
    $.fn.xmlNodeSearch = function (node) {
        return this.find('*').filter(function () {
            return this.nodeName === node
        })
    }
})(jQuery)

// HTML Preparation
function prepareHtml(html) {
    var newHtml = $('<div/>')
        .text(html)
        .html();
    newHtml = newHtml.replace(/&amp;nbsp;|&nbsp;/gi, '&#160;');
    var finalHtml = newHtml.replace(/&shy;/gi, '&#173;');
    return finalHtml;
}

// Set Display
function setDisplay(id, disp) {
  document.getElementById(id).style.display = disp;
}

// ThreatAdvice API Call
function apiCall(headers) {
    // var for phish id header
    var ph;
    // var for simulation id
    var sim_id = -1;

    // Look for phish simulation id header & set it
    if ((ph = headers.match(/X-ThreatAdvicePhishSimulationID: (.*)/)) != null) {
        // if phish header exists, then grab simulation id
        sim_id = parseInt(ph[1]);

        // NaN check
        if (isNaN(sim_id)) {
            sim_id = -1;
        }
    }

    // Call API
    var payload = {
        email: Office.context.mailbox.userProfile.emailAddress,
        sim_id: sim_id
    }

    $.post('https://www.mythreatadvice.com/api/v1/phish_reporter/user_info', payload, function(r) {
            // On Success
            // update receiving email
            receivingEmail = r.result.sendReportTo;
            
            // Show Success Message
            showSuccessMessage(r.result.isSimulation);
        })
        .fail(function(data) {
            Honeybadger.notify(data.responseJSON.err.msg, "ApiCallError");
            document.getElementById("loading-msg").innerHTML = "There was an issue reporting the email. Please contact your IT department if this issue remains.";
        });
    
}

// HoneyBadger Error Method
function createHBError(func, cont) {
    return {
        message: 'Outlook AddIn Error in ' + func + ' at ' + cont,
        name: 'OutlookAddInError',
        component: 'Outlook AddIn',
        action: func,
        context: cont,
        fingerprint: func,
        environment: 'production',
        projectRoot: 'https://phishreporteraddin.threatadvice.com/'
    }
}
